import React from 'react';
import { AppShell } from '@mantine/core';
import { useLocation } from 'react-router-dom';

// Hooks
import useUserLogged from '../../hooks/useUserLogged';

// Components
import PostLoginNavbar from './components/PostLoginNavbar/PostLoginNavbar';

// Styles
import styles from './postLoginLayout.module.scss';

const noNavbarPages = [
  /\/candidates\/.+\/jobs\/.+/,
  /console/,
  /cancel-appointment/,
];

const PostLoginLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { isUserLogged } = useUserLogged();

  const isNoNavbarRoute = noNavbarPages.some((routeRegex) =>
    routeRegex.test(pathname),
  );
  const hasNavbar = isUserLogged && !isNoNavbarRoute;

  return (
    <AppShell header={{ height: 50 }}>
      {hasNavbar && (
        <AppShell.Header>
          <PostLoginNavbar />
        </AppShell.Header>
      )}
      <AppShell.Main className={styles.main}>{children}</AppShell.Main>
    </AppShell>
  );
};

export default PostLoginLayout;
