import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

function useUserLogged() {
  const auth = useSelector((state) => state.firebase.auth);

  const isUserLogged = isLoaded(auth) && !isEmpty(auth);
  const user = isUserLogged ? auth : null;

  return { user, isUserLogged };
}

export default useUserLogged;
