import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import useUserLogged from '../../../hooks/useUserLogged';

import ErrorBoundary from '../ErrorBoundry/ErrorBoundary';

import PreLoginLayout from '../../Layouts/PreLoginLayout';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isUserLogged } = useUserLogged();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isUserLogged ? (
          <ErrorBoundary>
            <PreLoginLayout>
              <Component />
            </PreLoginLayout>
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.shape().isRequired,
};

export default PublicRoute;
